import classes from './HeadSection.module.css';
import MainPicutre from '../../assets/main_picture.png';
import ContactBar from '../contact-bar/ContactBar';
import useDownloadPdf from '../../hooks/useDownloadPdf';
import { RESUME_URL } from './constants';
const HeadSection = () => {
  const handleDownloadPdf = useDownloadPdf(RESUME_URL)
  return (
    <section className={classes.headSection}>
      <div className={classes.container}>
        <div className={classes.contactBarContainer}>
          <ContactBar />
        </div>
        <div className={classes.mainContent}>
          <div className={classes.infoContainer}>
            <div className={classes.textInfo}>
              <span className={classes.subTextOne}>Hello, I'm</span>
              <span className={classes.mainText}>PARAS VERMA</span>
              <span className={classes.subTextTwo}>A Front End Focused Web Developer</span>
            </div>
            <div className={classes.quickLinkContainer}>
              <button className={classes.resumeButton} onClick={handleDownloadPdf}>Resume</button>
            </div>
          </div>
          <div className={classes.backgroundImage}>
            <img src={MainPicutre} alt='Paras Verma'/>
          </div>
        </div>
      </div>
    </section>
    
  )
}

export default HeadSection;