import { createContext, useCallback, useMemo, useState } from "react";

const THEME_MODE = {
  DARK: 'dark',
  LIGHT: 'light',
}
const themeContext = createContext({theme: THEME_MODE.DARK, toggleTheme: () => {}});

const ThemeProvider = (props) => {
  const [theme, setTheme] = useState(THEME_MODE.DARK);

  const toggleTheme = useCallback(() => {
   setTheme((state) => state === THEME_MODE.DARK? THEME_MODE.LIGHT: THEME_MODE.DARK);
  }, [])

  const contextValue = useMemo(() => {
    return {theme: theme, toggleTheme: toggleTheme};
  }, [theme, toggleTheme])
  return (
    <themeContext.Provider value={contextValue}>
      {props.children}
    </themeContext.Provider>
  )
}

export { ThemeProvider, themeContext};