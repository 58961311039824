import classes from './TopWaveBorder.module.css';

const TopWaveBorder = () => {  
  return (
    <div className={classes.topWaveBorder}>
      <svg id="wave" style={{transform: 'rotate(0deg)', transition: '0.3s'}} viewBox="0 0 1440 210" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <path style={{transform: 'translate(0, 0px)', opacity: 1}} fill="currentColor" d="M0,168L48,147C96,126,192,84,288,84C384,84,480,126,576,147C672,168,768,168,864,154C960,140,1056,112,1152,115.5C1248,119,1344,154,1440,171.5C1536,189,1632,189,1728,182C1824,175,1920,161,2016,129.5C2112,98,2208,49,2304,49C2400,49,2496,98,2592,126C2688,154,2784,161,2880,147C2976,133,3072,98,3168,84C3264,70,3360,77,3456,80.5C3552,84,3648,84,3744,101.5C3840,119,3936,154,4032,140C4128,126,4224,63,4320,31.5C4416,0,4512,0,4608,0C4704,0,4800,0,4896,24.5C4992,49,5088,98,5184,129.5C5280,161,5376,175,5472,178.5C5568,182,5664,175,5760,147C5856,119,5952,70,6048,49C6144,28,6240,35,6336,31.5C6432,28,6528,14,6624,38.5C6720,63,6816,126,6864,157.5L6912,189L6912,210L6864,210C6816,210,6720,210,6624,210C6528,210,6432,210,6336,210C6240,210,6144,210,6048,210C5952,210,5856,210,5760,210C5664,210,5568,210,5472,210C5376,210,5280,210,5184,210C5088,210,4992,210,4896,210C4800,210,4704,210,4608,210C4512,210,4416,210,4320,210C4224,210,4128,210,4032,210C3936,210,3840,210,3744,210C3648,210,3552,210,3456,210C3360,210,3264,210,3168,210C3072,210,2976,210,2880,210C2784,210,2688,210,2592,210C2496,210,2400,210,2304,210C2208,210,2112,210,2016,210C1920,210,1824,210,1728,210C1632,210,1536,210,1440,210C1344,210,1248,210,1152,210C1056,210,960,210,864,210C768,210,672,210,576,210C480,210,384,210,288,210C192,210,96,210,48,210L0,210Z"></path></svg> 
    </div>

  )
}

export default TopWaveBorder;