import classes from './NavList.module.css';
import {  NAV_ITEMS } from '../constants';
import NavItem from './NavItem';

const NavList = () => {
  return  (
    <div className={classes.navListContainer}>
      <div className={classes.navList}>
        {NAV_ITEMS.map((item) => 
         <NavItem section={item.section} text={item.name}>
          <div className={classes.navItem}>{item.name}</div>
         </NavItem>
        )}
      </div>
    </div>
  )
}

export default NavList