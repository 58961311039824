import Heading from '../common/heading/Heading';
import ContactBar from '../contact-bar/ContactBar';
import ContactInfo from './ContactInfo';
import classes from './ContactSection.module.css';
import MessageBox from './MessageBox';

const ContactSection = () => {
  return (
    <section className={classes.contactSection}>
      <div className={classes.container}>
        <Heading text={'Contact'} />
        <div className={classes.contactBarContainer}>
          <ContactBar />
        </div>
        {/* <div className={classes.contactBody}> */}
          <MessageBox />
          <ContactInfo />
        {/* </div> */}

      </div>
    </section>
  )
}

export default ContactSection;