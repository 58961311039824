import Tag from '../common/tag/Tag';
import classes from './ExperienceCard.module.css';

const ExperienceCard = (props) => {
  const { companyName, position, tenure,
     location, summary, technologies } = props;
  return (
    <div className={classes.container}>
      <div className={classes.companyInfo}>
        <span className={classes.position}> {`${position} @`}</span>
        <span className={classes.companyName}> {companyName}</span>
        <span className={classes.tenure}>{tenure}</span>
        <div className={classes.location}>
          <span>{location}</span>
        </div>
      </div>
      <div className={classes.workInfo}>
        <div className={classes.summary}>
          {summary.map((text, index) => 
           <p>{`${index + 1}. ${text}`}</p>
          )}
        </div>
        <div className={classes.technologies}>
          {technologies.map((tech) => 
            <Tag text={tech}/>
          )}
        </div>
      </div>
    </div>
  )
}

export default ExperienceCard;