import { ICON_PATH_DATA } from '../../data/iconsPathData';
import IconSVG from '../common/icon/IconSVG';
import { SOCIAL_MEDIA } from './constants';
import classes from './ContactBar.module.css';

const ContactBar = () => {
  return (
    <div className={classes.container}>
      <IconSVG link={SOCIAL_MEDIA.LINKEDIN} width={'2.5rem'} height={'2.5rem'} pathData={ICON_PATH_DATA.LINKEDIN.PATH_DATA}/>
      <IconSVG link={SOCIAL_MEDIA.GITHUB} width={'2.5rem'} height={'2.5rem'} pathData={ICON_PATH_DATA.GITHUB.PATH_DATA}/>
      <IconSVG link={SOCIAL_MEDIA.FACEBOOK} width={'2.5rem'} height={'2.5rem'} pathData={ICON_PATH_DATA.FACEBOOK.PATH_DATA}/>
      <IconSVG link={SOCIAL_MEDIA.INSTAGRAM} width={'2.5rem'} height={'2.5rem'} pathData={ICON_PATH_DATA.INSTAGRAM.PATH_DATA}/>
    </div>
  )
}

export default ContactBar;