import AngularIcon from '../../../assets/icons/icons8-angularjs.svg';
import ReactIcon from '../../../assets/icons/icons8-react.svg';
import VueIcon from '../../../assets/icons/icons8-vue-js.svg';
import JavascriptIcon from '../../../assets/icons/icons8-javascript.svg';
import HTMLIcon from '../../../assets/icons/icons8-html-5.svg';
import CSSIcon from '../../../assets/icons/icons8-css3.svg';
import NodeIcon from '../../../assets/icons/icons8-nodejs.svg';
import FigmaIcon from '../../../assets/icons/icons8-figma.svg';
import MongoDBIcon from '../../../assets/icons/icons8-mongodb.svg';



const SKILL_DATA = [
  {
    skill: 'React',
    color: '#349EFF',
    iconImageSrc: ReactIcon,
  },
  {
    skill: 'Angular',
    color: '#FF4D4D',
    iconImageSrc: AngularIcon,
  },
  {
    skill: 'Vue',
    color: '#60D94C',
    iconImageSrc: VueIcon,
  },
  {
    skill: 'Javascript',
    color: '#E2CC1A',
    iconImageSrc: JavascriptIcon,
  },
  {
    skill: 'HTML',
    color: '#EF9201',
    iconImageSrc: HTMLIcon,
  },
  {
    skill: 'CSS',
    color: '#35C1F1',
    iconImageSrc: CSSIcon,
  },
  {
    skill: 'Node',
    color: '#858585',
    iconImageSrc: NodeIcon,
  },
  {
    skill: 'Figma',
    color: '#A442CD',
    iconImageSrc: FigmaIcon,
  },
  {
    skill: 'MongoDB',
    color: '#4CAF50',
    iconImageSrc: MongoDBIcon,
  },
]

export { SKILL_DATA };