import axios from 'axios';
import { useCallback, useState } from 'react';
import classes from './MessageBox.module.css';
import { url } from '../../configure';

const MessageBox = () => {

  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    message: '',
  });

  const [formErrors, setFormErrors] = useState({}); 
  const [serverError, setServerError] = useState('');
  const [messageSent, setMessageSent] = useState(false);
  
  const handleChange = useCallback((e) => {
    const { name, value } = e.target;

    setFormData( {
      ...formData,
      [name]: value
    })

  }, [formData])

  const resetForm = () => {
   setFormData({
    fullName: '',
    email: '',
    message: '',
   })
  };

  const validate = (data) => {
    let errors = {};
    if (!data.fullName) {
      errors.fullName = 'Fullname is required';
    }
    if (!data.email) {
      errors.email = 'Email is required';
    }
    if(!data.message) {
      errors.message = 'Message is required';
    }
    return errors;
  }

  const sendMessage = () => {
    setMessageSent(false)
    const errors = validate(formData);

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
    } else {
      setFormErrors({});
      const emailMessage = {
        fullName: formData.fullName,
        email: formData.email,
        message: formData.message,
      }
      
      axios.post(url + '/send-email', emailMessage)
      .then((response) => {
        console.log(response);

        setMessageSent(true);
        resetForm();
        setFormErrors({});
      })
      .catch((error) => {
        console.log(error)
        if (error.response) {
          setServerError(error.response.data)
        } else {
          setServerError('Error sending the message. Please try again later.')
        }
      })
    } 
  }

  return (
    <div className={classes.messageBoxContainer}>
      <div className={classes.textBox}>
        <span className={classes.mainText}>Thank You!</span>
        <span className={classes.secondaryText}>Do you have any queries?</span>
      </div>
      <div className={classes.inputBoxContainer}>
        <div className={classes.inputBox}>
          <div className={classes.fullNameContainer}>
            <div className={classes.labelContainer}>
              <span className={classes.label}>Full Name</span>
              {formErrors.fullName && 
              <span className={classes.error}>{formErrors.fullName}</span>
              }
            </div>
            <input type={''} className={classes.fullNameInput} name={'fullName'} onChange={handleChange} value={formData.fullName} />
          </div>
          <div className={classes.emailContainer}>
            <div className={classes.labelContainer}>
              <span className={classes.label}>Email</span>
              {formErrors.email && 
              <span className={classes.error}>{formErrors.email}</span>
              }
            </div>
            <input type='text' className={classes.emailInput} name={'email'} onChange={handleChange} value={formData.email}/>
          </div>
          <div className={classes.messageContainer}>
            <div className={classes.labelContainer}>
              <span className={classes.label}>Message</span>
              {formErrors.message && 
              <span className={classes.error}>{formErrors.message}</span>
              }
            </div>
            <textarea  className={classes.messageTextarea} name={'message'} onChange={handleChange} value={formData.message}/>
          </div>
          <div className={classes.submitContainer}>
            <button className={classes.submitButton} onClick={sendMessage}>Submit</button>
            {serverError && 
            <p className={classes.serverError}>{serverError}</p>
            }
            {messageSent && 
            <p className={classes.confirmationText}>
              Thank you for your message! I have received it and will get back to you as soon as possible.
            </p>
            }
          </div>
        </div>
        
      </div>
    </div>
  )
}

export default MessageBox;