const EXPERIENCE_DATA = [
  {
    companyName: 'Ford Motor Company',
    position: 'Software Developer',
    tenure: 'April 2021 - Present',
    location: 'Waterloo, CA',
    summary: ['Employed by Wipro Limited, working on Ford’s current and next-gen SYNC infotainment systems. Implemented the UI of various electric vehicle features on Human-Machine interface (HMI) that is used in Ford’s new electric cars (Mustang Mach-E, F150 lightning).',
    'Translated requirements and wireframes to create new HMI applications with React, Redux, and in-house component libraries.',
    'Collaborated with design team to implement a new UI layout resulting in a more cohesive and visually appealing user interfaces, and hence, user engagement on various apps increased significantly.',
    'Regularly collaborating with testers, designers and back-end developers to ensure timely delivery of software releases.',
    'Actively participating in agile development process, and contributing to project planning and estimation.'
  ],
    technologies: ['React', 'Redux', 'Flow', 'Javascript', 'CSS', 'HTML'],
  },
  {
    companyName: 'FlipOrders',
    position: 'Full Stack Developer',
    tenure: 'November 2020 - March 2021',
    location: 'Waterloo, CA',
    summary: ['Led a team to develop Fliporder’s food ordering web app and static website.',
    'Conceptualized, designed and developed various application features, such as Vendor Menu Creation and User Basket.',
    'Implemented online and terminal payment feature using Stripe’s Stripe.js library, and Integrated digital wallets like Apple Pay and Google Pay using Node JS and Express JS.',
    'Created wireframes and user flows using Figma and collaborated with the designer to come up with the design of the application.',
    'Developed the application using using React and Redux, and  styled it to be highly responsive to support mobile and desktop views.',
  ],
    technologies: ['React', 'Redux', 'Node js', 'Javascript', 'CSS', 'HTML', 'Firebase'],
  },
  {
    companyName: 'Nagarro Pvt. LTd.',
    position: 'Junior Developer',
    tenure: 'January 2018 - June 2019',
    location: 'Gurgram, India',
    summary: ['Implemented a product that is being used by the risk department of one of the leading banks of Europe. Based on microservices architecture, the UI of the application is a single-page application written using Angular.',
    'Worked on various other projects, where I developed reusable React components and used Redux for state management. Achieved responsiveness across different screen sizes using CSS and Bootstrap framework',
  ],
    technologies: ['React', 'Angular', 'Redux', 'Typescript', 'Javascript', 'CSS', 'HTML'],
  }
]

export { EXPERIENCE_DATA }; 