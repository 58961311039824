import { ICON_PATH_DATA } from '../../../data/iconsPathData';
import IconSVG from '../../common/icon/IconSVG';
const ThemeIcon = (props) => {
  const { theme, onClick } = props;

  const getIcon = () => {
    if(theme === 'dark') {
      return  (
        <IconSVG  onClick={onClick} width={35} height={35} pathData={ICON_PATH_DATA.SUN.PATH_DATA} viewBoxData={ICON_PATH_DATA.SUN.VIEW_BOX} />
      )
    } else {
      return (
        <IconSVG onClick={onClick} width={35} height={35} pathData={ICON_PATH_DATA.MOON.PATH_DATA} viewBoxData={ICON_PATH_DATA.MOON.VIEW_BOX} />
    )
    }
  }
  return (
    getIcon()
  )
}

export default ThemeIcon;