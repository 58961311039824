import IconSVG from '../../common/icon/IconSVG';
import NavItem from './NavItem';
import { ICON_PATH_DATA } from '../../../data/iconsPathData';
import { SOCIAL_MEDIA } from '../../contact-bar/constants';
import { NAV_ITEMS } from '../constants';
import classes from './mobileNavbar.module.css';

const MobileNavbar = (props) => {
  return(
    <>
      <div className={classes.overlay} onClick={props.onOverlayClick}>
      </div>
      <div className={classes.mobileNavbarContainer}>
        <div className={classes.mobileNavbar}>
        {NAV_ITEMS.map((item) => 
          <NavItem section={item.section} onClick={props.onNavItemClick}>
            <div className={classes.navItem} >{item.name}</div>
          </NavItem>
          )}
        <div className={classes.navItem}>
          <div className={classes.contactItems}>
            <IconSVG link={SOCIAL_MEDIA.LINKEDIN} width={'1.7rem'} height={'1.7rem'} pathData={ICON_PATH_DATA.LINKEDIN.PATH_DATA}/>
            <IconSVG link={SOCIAL_MEDIA.GITHUB} width={'1.7rem'} height={'1.7rem'} pathData={ICON_PATH_DATA.GITHUB.PATH_DATA}/>
            <IconSVG link={SOCIAL_MEDIA.FACEBOOK} width={'1.7rem'} height={'1.7rem'} pathData={ICON_PATH_DATA.FACEBOOK.PATH_DATA}/>
            <IconSVG link={SOCIAL_MEDIA.INSTAGRAM} width={'1.7rem'} height={'1.7rem'} pathData={ICON_PATH_DATA.INSTAGRAM.PATH_DATA}/>
          </div>

        </div>
        </div>
      </div>
    </>
  )
}

export default MobileNavbar