import Skill from './Skill';
import { SKILL_DATA } from './skillData';
import classes from './SkillList.module.css';

const SkillList = () => {
  return (
    <div className={classes.container}>
      {/* <div className={classes.skillList}> */}
      {
        SKILL_DATA.map((skill) => 
        <Skill text={skill.skill} color={skill.color} iconImageSrc={skill.iconImageSrc} />
        )
      }
      {/* </div> */}
     
    </div>
  )
}

export default SkillList;