import IconSVG from '../common/icon/IconSVG';
import classes from './ContactInfo.module.css';
import { ICON_PATH_DATA } from '../../data/iconsPathData';
const ContactInfo = () => {
  return (
    <div className={classes.contactInfo}>
      <div className={classes.contact}>
        <IconSVG width={35} height={35} cursor={false} pathData={ICON_PATH_DATA.MAIL.PATH_DATA} />
        <span className={classes.contactLabel}>v.paras2@gmail.com</span>
      </div>
      <div className={classes.contact}>
        <IconSVG width={35} height={35} cursor={false} pathData={ICON_PATH_DATA.PHONE.PATH_DATA} viewBoxData={ICON_PATH_DATA.PHONE.VIEW_BOX}/>
        <span className={classes.contactLabel}>+1-226-507-4804</span>
      </div>
      <div className={classes.contact}>
        <IconSVG width={35} height={35} cursor={false} pathData={ICON_PATH_DATA.LOCATION.PATH_DATA} viewBoxData={ICON_PATH_DATA.LOCATION.VIEW_BOX} />
        <span className={classes.contactLabel}>Toronto, Canada</span>
      </div>
    </div>
  )
}

export default ContactInfo;