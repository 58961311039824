import Heading from '../common/heading/Heading';
import classes from './ExperienceSection.module.css';
import ExperienceCard from './ExperienceCard';
import { EXPERIENCE_DATA } from '../../data/ExperienceData';

const ExperienceSection = () => {
  return (
    <section className={classes.experienceSection}>
      <div className={classes.container}>
        <Heading text={'Experience'} />
        <div className={classes.infoContainer}>
          {EXPERIENCE_DATA.map((experience) =>  
          
              <ExperienceCard 
              companyName={experience.companyName}
              position={experience.position}
              tenure={experience.tenure}
              location={experience.location}
              summary={experience.summary}
              technologies={experience.technologies}
              />
            
          )}
        </div>
      </div>
    </section>
  )
}

export default ExperienceSection;