import classes from './Tag.module.css';
const Tag = (props) => {

  return (
    <div className={classes.tag}>
      <span>{props.text}</span>
    </div>
  )
}

export default Tag;