import Heading from '../common/heading/Heading';
import classes from './AboutMeSection.module.css';
import { ABOUT_ME } from './skillData';
import SkillList from './skillList/SkillList';

const AboutMeSection = () => {
  return (
    <section className={classes.aboutMeSection}>
      <div className={classes.container}>
        <Heading text={'About Me'} />
        <div className={classes.infoContainer}>
          <div className={classes.aboutMeInfo}>
            <div className={classes.subHeadingOne}>
              <span>Who is Paras?</span>
            </div>
            <div className={classes.infoContent}>
              {
                ABOUT_ME.map((data) => 
                <p>{data}</p>
                )
              }
            </div>
          </div>
          <div className={classes.skillsInfo}>
            <div className={classes.subHeadingTwo}>
              <span>What's in his toolbox?</span>
            </div>
            <div className={classes.skillContent}>
              <SkillList />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default AboutMeSection;