import classes from './Skill.module.css';

const Skill = (props) => {
  const { iconImageSrc, text, color } = props;
  return (
    <div className={classes.skill}>
      <div className={classes.contentBody}>
        {/* <div className={classes.icon}>A</div> */}
        <img className={classes.icon} src={iconImageSrc} alt={iconImageSrc} />
        <span style={{ color: color}} className={classes.text}>{text}</span>
      </div>
      <div style={{ backgroundColor: color}} className={classes.footer}>
      </div>
    </div>
  )
}

export default Skill;