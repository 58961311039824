const useDownloadPdf = (pdfUrl) => {
  const handleDownloadPdf = () => {
    const link = document.createElement('a');
    link.href = pdfUrl;
    link.download = 'resume.pdf';
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  return handleDownloadPdf;
}

export default useDownloadPdf;