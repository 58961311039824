import classes from './IconSVG.module.css';
const IconSVG = (props) => {
  const { link, width, height, onClick, pathData, viewBoxData, cursor = true } = props;
  const iconWidth = width? width: 50;
  const iconHeight = height? height: 50;

  const doNothing = () => {};


  const icon = (
    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
    width={`${iconWidth}`} height={`${iconHeight}`}
    viewBox={viewBoxData? viewBoxData: "0 0 50 50"}
    className={`${classes.icon} ${cursor && classes.withCursor}`}
    onClick={onClick? onClick: doNothing}
    >
      <path fill='currentColor' d={pathData}></path>
    </svg>
  )
  return (
   link? 
   <a href={link} target="_blank" rel="noopener noreferrer">
    {icon}
   </a>
   :
   icon
  )
}

export default IconSVG;