import classes from './Footer.module.css';
const Footer = () => {
  return (
    <div className={classes.footer}>
      <div className={classes.container}>
        <div className={classes.body}>
          <div className={classes.leftText}>
            <span>© Paras Verma. All Rights Reserved</span>
          </div>
          <div className={classes.rightText}>
            <span>Designed & Built by Paras Verma</span>
          </div>
        </div>
       
      </div>
    </div>
  )
}

export default Footer;