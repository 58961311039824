const NAVBAR_HEIGHT = 80;
const NAV_ITEMS = [
  {
    name: 'Home',
    section: 'home'
  },
  {
    name: 'About Me',
    section: 'about'
  },
  {
    name: 'Experience',
    section: 'experience'
  },
  {
    name: 'Projects',
    section: 'projects'
  },
  {
    name: 'Contact',
    section: 'contact'
  },
]
export { NAVBAR_HEIGHT, NAV_ITEMS }